<template>
    <div class="sbs-edu-training-post-item" :class="{ locked: locked }" @click="onItemClick(item)">

        <div class="success" v-if="item.result && item.result.status == 'S'">
            <span class="icon fi fi-save-bold"></span>
        </div>

        <div class="picture" :style="{ paddingTop:heightPicture+'%' }">

            <div v-if="item.picture" class="image preloaded" v-lazy:background-image="item.picture.preview">

                <div class="preload" v-if="item.picture.preload"
                    :style="{ backgroundImage: 'url(\'' + item.picture.preload + '\')' }">
                </div>

                <div class="loader">
                    <sbs-loader-inline />
                </div>
            </div>

            <div v-else class="image no-photo" :style="{ backgroundImage: 'url(' + imageNoPhoto + ')' }"></div>

            <div v-if="locked" class="lock">
                <div class="lock-content">
                    <div><span class="fi fi-lock icon"></span></div>
                    <div class="text">
                        {{ $t("education.training.posts.lock") }}
                        <br />
                        {{ $t("education.training.posts.lock_description") }}
                    </div>

                </div>
            </div>

        </div>

        <div class="inner">
            <div class="name" v-html="item.name"></div>
            <div class="description" v-if="item.description" v-html="item.description"></div>

        </div>


    </div>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";

import imageNoPhotoPath from "@/assets/images/no-photo.svg";

export default {
    name: "sbs-education-training-post-item",
    components: {
        sbsLoaderInline,
    },
    data() {
        return {
            imageNoPhoto: imageNoPhotoPath
        };
    },
    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },
        index: {
            type: Number,
            default() {
                return 0;
            }
        },
        locked: {
            type: Boolean,
            default() {
                return true;
            }
        },
        trainingId: {
            type: [String, Number],
            default() {
                return 0;
            }
        }
    },
    computed:{
        heightPicture(){
            if(this.item.picture){
                return this.item.picture.height/this.item.picture.width*100;
            }
            return 60;
        },
    },
    methods: {


        /**
         * Переход к посту
         */
        onItemClick(item) {

            if (this.locked) {
                return;
            }

            this.$router.push({
                name: "education-training-post-view",
                params: { id: item.id, trainingId: this.trainingId },
            });
        },
    },
};
</script>

<style lang="scss">
.sbs-edu-training-post-item {
    position: relative;

    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 8px;

    background-color: var(--color-block-background);

    .picture {
        height: 0px;
        padding-top: 60%;
        border-radius: 8px 8px 0px 0px;
        overflow: hidden;
        position: relative;

        .image {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;

            &.no-photo {
                background-size: contain;
            }
        }

        .lock {
            position: absolute;
            z-index: 10;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;

            //цвет фиксируем, так как отображается на тёмном фоне всегда
            color: #fff;

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;
                z-index: 11;
                //цвет фиксируем, так как в любой теме будет затенение
                background-color: #000;
                opacity: 0.5;
            }

            .lock-content {
                position: absolute;
                left: 30px;
                right: 30px;
                text-align: center;
                top: 50%;
                transform: translateY(-50%);
                z-index: 12;

                .icon {
                    font-size: 40px;
                }

                .text {
                    margin-top: 10px;
                }
            }
        }
    }

    .inner {
        padding: 16px 10px;
    }

    .name {
        font-size: 14px;
        font-weight: 500;
    }

    .description {
        font-size: 12px;
    }

    .success {
        position: absolute;
        top: 5px;
        left: 5px;
        line-height: 1;
        z-index: 10;

        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: var(--color-success);

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

        }
    }


}
</style>