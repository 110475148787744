<template>
    <div class="sbs-edu-post-item" @click="onItemClick(item)">

        <div class="author-date">
            <div class="date">{{ formatDate() }}</div>
            <div class="author">
                <div>
                    <div class="avatar">
                        <div v-if="!item.user_photo" class="initials">
                            {{ item.user_last_name.charAt(0) + item.user_name.charAt(0) }}
                        </div>
                        <div v-else class="photo" :style="{
        backgroundImage: 'url(' + item.user_photo.preview + ')',
    }"></div>
                    </div>
                </div>
                <div>
                    <div class="fio">
                        {{ item.user_last_name }} {{ item.user_name }}
                    </div>
                </div>
            </div>
        </div>

        <div class="name" v-html="item.name"></div>

        <div class="tags" v-if="item.tags && item.tags.length > 0">
            <sbs-education-post-item-tags :item="item" />
        </div>

        <div class="description" v-if="item.description" v-html="item.description"></div>

        <div v-if="item.picture" class="picture preloaded" v-lazy:background-image="item.picture.preview" :style="{ paddingTop:item.picture.height/item.picture.width*100+'%' }">

            <div class="preload" v-if="item.picture.preload"
                :style="{ backgroundImage: 'url(\'' + item.picture.preload + '\')' }">
            </div>

            <div class="loader">
                <sbs-loader-inline />
            </div>
        </div>

        <div class="stat">
            <sbs-education-post-item-stat :item="item" />
        </div>

    </div>


</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsEducationPostItemStat from "@/components/education/post.item.stat.vue";
import sbsEducationPostItemTags from "@/components/education/post.item.tags.vue";

import moment from "moment";

export default {
    name: "sbs-education-post-item",
    components: {
        sbsLoaderInline,
        sbsEducationPostItemStat,
        sbsEducationPostItemTags
    },
    data() {
        return {
        };
    },
    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        }
    },

    methods: {

        formatDate() {
            let date = moment(this.item.publication_date, "DD.MM.YYYY HH:mm:ss");
            let days = moment().diff(date, 'days');
            if (days <= 3) {
                return date.fromNow();
            }
            return date.format("DD MMMM YYYY");
        },

        /**
         * Переход к посту
         */
        onItemClick(item) {
            this.$router.push({
                name: "education-post-view",
                params: { id: item.id },
            });
        },


    },
};
</script>

<style lang="scss">
.sbs-edu-post-item {
    position: relative;

    margin-bottom: 20px;
    cursor: pointer;

    padding: 10px;
    padding-bottom: 40px;
    border-radius: 8px;
    background-color: var(--color-block-background);

    .author-date {
        margin-bottom: 10px;
    }

    .author {
        display: flex;
        column-gap: 10px;
        align-items: center;

        .avatar {
            position: relative;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: var(--color-chat-fio);

            .initials {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: var(--color-chat-background);
            }

            .photo {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                background-size: cover;
            }
        }

        .fio {
            font-size: 12px;
            font-weight: 500;
            color: var(--color-chat-fio);
        }
    }

    .date {
        font-size: 12px;
        color: var(--color-chat-fio);
        float: right;
    }

    .name {
        font-size: 21px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .tags {
        margin-bottom: 10px;
    }

    .description {
        font-size: 14px;
        margin-bottom: 10px
    }

    .picture {
        height: 0px;
        background-size: cover;
        background-position: center center;
        border-radius: 8px;
        background-color: var(--color-block-background);
    }

    .stat {
        position: absolute;
        left: 10px;
        right: 10px;
        bottom: 5px;


    }
}
</style>