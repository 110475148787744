<template>
    <div class="sbs-edu-posts-pinned" v-if="this.items.length > 0">
        <h2>{{ $t('education.posts.title_pinned')}}</h2>
        <swiper :slidesPerView="'auto'" :loop="false" :spaceBetween="0" :autoHeight="false" class="mySwiper">
            <swiper-slide v-for="item in items" :key="item.id" class="swiper-item">
                    <sbs-education-post-item-pinned  :item="item" class="item" />
            </swiper-slide>
        </swiper>  
    </div>
</template>

<script>
import sbsEducationPostItemPinned from "@/components/education/post.item.pinned.vue"

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

export default {
    name: "sbs-v-education-posts-pinned",
    components:{
        sbsEducationPostItemPinned,
        Swiper,
        SwiperSlide,   
    },
    data() {
        return {
            items: [],
        };
    },
    computed: {},
    watch:{},
    methods:{

        /**
         * Загружает данные
         */
        loadData(resolve = false) {

            //запрашиваем данные
            rest
                .call("education.post.list", {
                    method: "post",
                    data: {
                        select: [],
                        resize_options: {
                            PREVIEW: { WIDTH: 400, HEIGHT: 300 },
                            PRELOAD: "Y"
                        },
                        order: {
                            "publication_date": "desc"
                        },
                        filter: {pinned:"Y"},
                    },
                })
                .then((data) => {
                    if (data.success) {
                        this.items = data.items;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    if (resolve) {
                        resolve();
                    }

                });
        },
    },

    created() {
        this.loadData();
    },

}
</script>
<style lang="scss">
.sbs-edu-posts-pinned{
    .swiper-item{
        margin-right: 0 !important;
        width: 110px;
        padding-left: 0;
        padding-right: 15px;
    }
}
</style>