<template>
    <div class="sbs-edu-post-item-pinned" @click="onItemClick(item)">
        <div class="picture preloaded" v-if="item.picture" v-lazy:background-image="item.picture.preview">

            <div class="preload" v-if="item.picture.preload"
                :style="{ backgroundImage: 'url(\'' + item.picture.preload + '\')' }">
            </div>

        </div>
        <div class="picture" v-else></div>
        
        <div class="text-block">
            <div class="date">{{ formatDate() }}</div>
            <div class="name" v-html="this.item.name"></div>
        </div>


    </div>


</template>

<script>
import moment from "moment";

export default {
    name: "sbs-education-post-item",
    components: {
    },
    data() {
        return {
        };
    },
    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        }
    },
    computed:{},
    methods: {

        formatDate() {
            let date = moment(this.item.publication_date, "DD.MM.YYYY HH:mm:ss");
            let days = moment().diff(date, 'days');
            if (days <= 3) {
                return date.fromNow();
            }
            return date.format("DD MMMM YYYY");
        },

        /**
         * Переход к посту
         */
        onItemClick(item) {
            this.$router.push({
                name: "education-post-view",
                params: { id: item.id },
            });
        },


    },
};
</script>

<style lang="scss">
.sbs-edu-post-item-pinned {
    position: relative;

    margin-bottom: 20px;
    cursor: pointer;

    border-radius: 8px;
    background-color: var(--color-block-background);

    .text-block{
        padding:5px 10px 10px 10px;

        .date {
            font-size: 10px;
            color: var(--color-chat-fio);
        }
        .name {
            font-size: 12px;
            font-weight: 400;
            line-height: 1.1;
            height: 2.2em;
            padding-top:5px;
            overflow: hidden;
            overflow-wrap: break-word;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            white-space: pre-wrap;
        }
    }
    .picture {
        height: 0px;
        padding-top: 60%;
        background-size: cover;
        background-position: center center;
        border-radius: 8px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        background-color: var(--color-block-background);
    }
}
</style>